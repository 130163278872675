<template>
<div class="boolean-options">
  <div class="option-preview">
    <icon icon="thumbs-up" class="has-text-success fa-flip-horizontal" type="far"/>
    <p class="has-text-weight-bold">{{ checkpoint.handler_payload.ok_label }}</p>
  </div>
  <div class="option-preview">
    <icon icon="thumbs-down" class="has-text-danger fa-flip-horizontal" type="far"/>
    <p class="has-text-weight-bold">{{ checkpoint.handler_payload.failure_label }}</p>
  </div>
  <div class="option-preview" v-show="checkpoint.handler_payload.with_na">
    <icon icon="circle-notch"/>
    <p class="has-text-weight-bold">{{ checkpoint.handler_payload.na_label }}</p>
  </div>
</div> 
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  
  computed: mapGetters('checkpoint', [
    'checkpoint'
  ])

}
</script>